import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { DetailsCard, DetailsCardProps } from '@amzn/imdb-shared-meridian-components/components/DetailsCard';
import { GenericErrorCard } from '@amzn/imdb-shared-meridian-components/components/error/GenericErrorCard';
import { RequestErrorCard } from '@amzn/imdb-shared-meridian-components/components/error/RequestErrorCard';
import Alert from '@amzn/meridian/alert';
import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import Loader from '@amzn/meridian/loader';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';

import { ListDetailsResponse, ListFlag } from '../../../listoramaAdmin-api/generated-src/api';
import { AuthorDetailsTable } from '../../components/AuthorDetailsTable/AuthorDetailsTable';
import { ActionButtonProps, ButtonId, ListActionButtons } from '../../components/ListActionButtons/ListActionButtons';
import { ListEditData, ModalData } from '../../components/ListActionModal/ListActionModal';
import { ListItemsDataTable } from './components/ListItemsDataTable/ListItemsDataTable';
import { ListMainDetailsTable } from './components/ListMainDetailsTable/ListMainDetailsTable';
import { ListDetails, ListItemsTextQuery } from './ListDetailsSection';

type LeftColumnCardId = 'List Details' | 'Author';
type RightColumnCardId = 'Items';

interface ListDetailsSectionViewProps {
    searchInput: string;
    listDetails: ListDetails;
    listEditData: ListEditData;
    listItemsTextQuery: ListItemsTextQuery;
    setListEditData: Dispatch<SetStateAction<ListEditData>>;
    modalData: ModalData;
    setModalData: Dispatch<SetStateAction<ModalData>>;
    editListState: (data: ListEditData) => Promise<void>;
    addListFlag: (data: ListEditData) => Promise<void>;
    removeListFlag: (data: ListEditData) => Promise<void>;
    canLoadMore: boolean;
    onLoadMore: () => void;
}

export const TEST_ID = {
    LOAD_MORE_BUTTON: 'load-more-button'
};

export const ListDetailsSectionView: React.VFC<ListDetailsSectionViewProps> = (props: ListDetailsSectionViewProps) => {
    const {
        searchInput,
        listDetails,
        listEditData,
        listItemsTextQuery,
        setListEditData,
        modalData,
        setModalData,
        editListState,
        addListFlag,
        removeListFlag,
        canLoadMore,
        onLoadMore
    } = props;

    const createActionButtons = (list: ListDetailsResponse['list']): ActionButtonProps<ButtonId>[] => [
        {
            id: 'edit_list_state',
            text: 'Edit List State',
            onConfirm: editListState
        },
        {
            id: 'add_list_flag',
            text: 'Add List Flag',
            onConfirm: addListFlag,
            disabled: list.flags.length >= Object.keys(ListFlag).length
        },
        {
            id: 'remove_list_flag',
            text: 'Remove List Flag',
            onConfirm: removeListFlag,
            disabled: list.flags.length === 0
        }
    ];

    const createLeftColumnCards = (
        listDetails: ListDetailsResponse,
        actionButtons: ActionButtonProps<ButtonId>[]
    ): DetailsCardProps<LeftColumnCardId>[] => [
        {
            name: 'List Details',
            CustomCardContentElement: (
                <Column>
                    <ListMainDetailsTable list={listDetails.list} />
                    <ListActionButtons
                        list={listDetails.list}
                        buttons={actionButtons}
                        listEditData={listEditData}
                        setListEditData={setListEditData}
                        modalData={modalData}
                        setModalData={setModalData}
                    />
                </Column>
            )
        },
        {
            name: 'Author',
            CustomCardContentElement: <AuthorDetailsTable author={listDetails.author} />
        }
    ];

    const createRightColumnCards = (
        list: ListDetailsResponse['list'],
        canLoadMore: boolean,
        onLoadMore: () => void
    ): DetailsCardProps<RightColumnCardId>[] => [
        {
            name: 'Items',
            CustomCardContentElement: (
                <Column>
                    {canLoadMore ? (
                        <Alert>
                            <Row spacing='300' width='330px' wrap='down'>
                                <Text type='h100'>There are more items to load.</Text>
                                <Button
                                    size='small'
                                    type='tertiary'
                                    onClick={onLoadMore}
                                    data-testid={TEST_ID.LOAD_MORE_BUTTON}
                                >
                                    Load More
                                </Button>
                            </Row>
                        </Alert>
                    ) : null}
                    <Heading level={5}>{`List size: ${list.size}`}</Heading>
                    <ListItemsDataTable
                        listItems={list.items}
                        listItemsText={listItemsTextQuery.data ? listItemsTextQuery.data.consts : []}
                    />
                </Column>
            )
        }
    ];

    const isLoading = () => listDetails.fetching || listItemsTextQuery.fetching;

    const isError = () => listDetails.error || listItemsTextQuery.error;

    const showLoader = () => {
        return (
            <>
                <Loader />
                <Text type='b500'>{listDetails.fetchingMessage ?? 'Loading...'}</Text>
            </>
        );
    };

    const showError = () => {
        if (listDetails.error) {
            return (
                <RequestErrorCard
                    error={listDetails.error}
                    permissionsRequestLink='https://t.corp.amazon.com/create/templates/c7fd4945-0e05-4eed-bba2-60dd532238c7'
                />
            );
        } else if (listItemsTextQuery.error) {
            return <GenericErrorCard message={`Encountered an error: ${listItemsTextQuery.error.message}`} />;
        } else {
            return <GenericErrorCard message={`${searchInput} is not a valid List ID. Please try again.`} />;
        }
    };

    const showListDetails = () => {
        if (!listDetails.data) {
            return null;
        } else {
            const actionsButtons = createActionButtons(listDetails.data.list);
            const leftColumnCards = createLeftColumnCards(listDetails.data, actionsButtons);
            const rightColumnCards = createRightColumnCards(listDetails.data.list, canLoadMore, onLoadMore);
            return (
                <ListDetailsContainer>
                    <Column>
                        <Heading level={1}>{`List ${listDetails.data.list.listId}`}</Heading>
                        <Row alignmentVertical='top'>
                            <Column width='50%'>
                                {leftColumnCards.map((card) => (
                                    <DetailsCard
                                        key={card.name}
                                        name={card.name}
                                        CustomCardContentElement={card.CustomCardContentElement}
                                    />
                                ))}
                            </Column>
                            <Column width='50%'>
                                {rightColumnCards.map((card) => (
                                    <DetailsCard
                                        key={card.name}
                                        name={card.name}
                                        CustomCardContentElement={card.CustomCardContentElement}
                                    />
                                ))}
                            </Column>
                        </Row>
                    </Column>
                </ListDetailsContainer>
            );
        }
    };

    return <Container>{(isLoading() && showLoader()) || (isError() && showError()) || showListDetails()}</Container>;
};

const ListDetailsContainer = styled.div`
    width: 100%;
    height: 100%;
    text-align: left;
    overflow: scroll;
    padding: 15px;
`;

const Container = styled.div`
    text-align: center;
`;
