import React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useGlobalContext } from '../../../context/useGlobalContext';
import { ListDetailsSection } from '../../features/ListDetailsSection/ListDetailsSection';

export const LIST_ID_PARAM = 'listId';

export const ListDetails: React.FC = () => {
    const { searchInput } = useGlobalContext();
    const navigate = useNavigate();

    useEffect(() => {
        if (!searchInput) {
            navigate('/');
        }
    }, [searchInput]);

    return <ListDetailsSection />;
};
