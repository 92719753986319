import React, { useState } from 'react';
import { Outlet as ChildrenRoutes } from 'react-router-dom';

import { PageSection } from '@amzn/imdb-shared-meridian-components/components/PageSection';
import { ResetCssStyles } from '@amzn/imdb-shared-meridian-components/components/ResetCssStyles';
import Alert from '@amzn/meridian/alert';
import AppLayout from '@amzn/meridian/app-layout';
import Theme from '@amzn/meridian/theme';
import Toaster from '@amzn/meridian/toaster';
import { ToasterToasts } from '@amzn/meridian/toaster/toaster';

import passThroughTheme from '../../assets/passThroughTheme.json';
import customTheme from '../../assets/theme.json';
import { useGlobalContext } from '../../context/useGlobalContext';
import { Header } from './Header/Header';
import { SideBar } from './SideBar/SideBar';

let incrementingId = 0;
const toastVisibilityTimeout = 10000; // 10 seconds in milliseconds

export const Layout: React.FC = () => {
    const context = useGlobalContext();
    const { isPassThroughMode } = context;
    const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(true);

    const [toasts, setToasts] = useState<ToasterToasts[]>([]);
    const onCloseToast = (id) => setToasts(toasts.filter((t) => t.id !== id));
    const onOpenToast = () => setToasts(toasts.concat({ id: `${++incrementingId}`, timeout: toastVisibilityTimeout }));

    const themePalette = isPassThroughMode ? passThroughTheme : customTheme;

    const [showAlert, setShowAlert] = useState(!isPassThroughMode);

    return (
        <Theme palette={themePalette} mode={'auto'}>
            <>
                <ResetCssStyles />
                <AppLayout headerComponent={Header} sidebarComponent={SideBar} backgroundColor={themePalette.palette50}>
                    <Toaster toasts={toasts} onCloseToast={onCloseToast}>
                        {(toast) => (
                            <Alert toast={true} onClose={toast.onClose}>
                                Not a valid customerId or ListId. Did you enter a userId by mistake?
                            </Alert>
                        )}
                    </Toaster>
                    <Header
                        isSideBarOpen={isSideBarOpen}
                        setIsSideBarOpen={setIsSideBarOpen}
                        onOpenToast={onOpenToast}
                    />
                    <SideBar isSideBarOpen={isSideBarOpen} />
                    <PageSection>
                        {showAlert && (
                            <Alert
                                onClose={() => setShowAlert(false)}
                                type='warning'
                                title='Currently viewing data in ListORama instead of Oskar.'
                            >
                                Enable Oskar Pass-Through Mode to view data that customers see.
                            </Alert>
                        )}
                        <ChildrenRoutes context={context} />
                    </PageSection>
                </AppLayout>
            </>
        </Theme>
    );
};
