import React from 'react';
import { useSearchParams } from 'react-router-dom';

import Column from '@amzn/meridian/column';
import Toggle from '@amzn/meridian/toggle';

import { setListORamaPassThroughFlag } from '../../../config/axiosConfig';
import { useGlobalContext } from '../../../context/useGlobalContext';

export const OSKAR_PASS_THROUGH_PARAM = 'passThrough';

export const TEST_ID = 'oskar-pass-through-toggle';

export const OskarPassThroughToggle: React.FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { isPassThroughMode } = useGlobalContext();

    // Store toggle state into browser session state so we can recover pass-through mode query param if it is not
    // present (such as during page navigation).

    const togglePassThrough = (newState: boolean): void => {
        searchParams.set(OSKAR_PASS_THROUGH_PARAM, newState.toString());
        setListORamaPassThroughFlag(newState);
        setSearchParams(searchParams);
    };
    return (
        <Column>
            <Toggle
                checked={isPassThroughMode}
                onChange={(newState: boolean) => togglePassThrough(newState)}
                data-testid={TEST_ID}
            >
                Oskar Pass-through Mode
            </Toggle>
        </Column>
    );
};
