import React, { useState, useEffect } from 'react';

import { SearchBar } from '@amzn/imdb-shared-meridian-components/components/SearchBar';
import Masthead, { MastheadTitle, MastheadMenuButton, MastheadLink } from '@amzn/meridian/masthead';
import Select, { SelectOption } from '@amzn/meridian/select';

import { useGlobalContext } from '../../../context/useGlobalContext';
import { validateListConst, validateCustomerId } from '../../../utils/validation';
import { ListIdObfuscationTool } from '../../features/ListIdObfuscationTool/ListIdObfuscationTool';
import { OskarPassThroughToggle } from '../OskarPassThroughToggle/OskarPassThroughToggle';

export const TEST_ID = {
    MENU_BUTTON: 'headerMenuButton',
    SEARCH_BAR: 'headerSearchBar'
};

interface Props {
    isSideBarOpen: boolean;
    setIsSideBarOpen: (isOpen: boolean) => void;
    onOpenToast: () => void;
}

const STAGES = ['Alpha', 'Beta', 'Gamma', 'Prod'];
const SERVICE_TITLE = `IMDbListORamaAdmin`;

export const Header: React.FC<Props> = (props) => {
    const { searchInput, navigateToList, navigateToUser } = useGlobalContext();

    const { isSideBarOpen, setIsSideBarOpen, onOpenToast } = props;
    const { currentStage } = useGlobalContext();
    const [searchTextValue, setSearchTextValue] = useState<string>(searchInput);

    useEffect(() => {
        setSearchTextValue(searchInput);
    }, [searchInput]);

    const onSearchSubmit = (input: string) => {
        const inputString = input.trim();
        if (validateListConst(inputString)) {
            navigateToList(inputString);
        } else if (validateCustomerId(inputString)) {
            navigateToUser(inputString);
        } else {
            onOpenToast();
        }
    };

    const handleStageSelect = (newStage: string, currentStage: string) => {
        if (newStage !== currentStage) {
            location.href = `https://${newStage}.listorama-admin.imdb.amazon.dev`;
        }
    };

    const handleMenuButtonClick = () => {
        setIsSideBarOpen(!isSideBarOpen);
    };

    return (
        <Masthead>
            <MastheadMenuButton onClick={handleMenuButtonClick} data-testid={TEST_ID.MENU_BUTTON} />
            <MastheadTitle href='/'>{SERVICE_TITLE}</MastheadTitle>
            <MastheadLink>
                <Select id='headerStageSelector' placeholder='Alpha' value={currentStage} onChange={handleStageSelect}>
                    {STAGES.map((stage) => (
                        <SelectOption
                            key={`${stage}SelectOption`}
                            data-test-id={`${stage}SelectOption`}
                            value={stage}
                            label={stage}
                            aria-disabled={stage === currentStage}
                        />
                    ))}
                </Select>
            </MastheadLink>

            <>
                <OskarPassThroughToggle />
                <ListIdObfuscationTool />
                <SearchBar
                    id='headerSearchBar'
                    placeholder='Search by list ID or customerid...'
                    value={searchTextValue}
                    searchButton
                    onChange={(value) => {
                        setSearchTextValue(value);
                    }}
                    onSubmit={onSearchSubmit}
                    containerWidth={400}
                    data-testid={TEST_ID.SEARCH_BAR}
                />
            </>
        </Masthead>
    );
};
