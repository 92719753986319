import React from 'react';

import { CustomRowTableCellProps, SelectableOptions } from '@amzn/imdb-shared-meridian-components/components/DataTable';
import Link from '@amzn/meridian/link';

import { useGlobalContext } from '../../../../../context/useGlobalContext';
import { ListDetails, PredefinedListDetails } from '../../../../../listoramaAdmin-api/generated-src/api';
import { ListDataTable } from '../../../../components/ListDataTable/ListDataTable';
import { HEADERS_CONFIG } from './TableHeadersConfig';

export enum ListType {
    LIST = 'LIST',
    PREDEFINED = 'PREDEFINED'
}

export type ColumnId =
    | 'listId'
    | 'name'
    | 'size'
    | 'state'
    | 'publik'
    | 'listClass'
    | 'listType'
    | 'modified'
    | 'created'
    | 'flags';

export type SelectedLists = { [listId: string]: boolean };

interface ListsDataTableProps {
    lists: ListDetails[] | PredefinedListDetails[];
    listType: ListType;
    selectableOptions?: SelectableOptions;
}

export const LIST_FLAGS_DELIMITER = ', ';

const ITEMS_PER_PAGE_OPTIONS = [15, 50, 100];

export const UserListsDataTable: React.FC<ListsDataTableProps> = (props: ListsDataTableProps) => {
    const { lists, listType, selectableOptions } = props;

    return (
        <ListDataTable
            headers={HEADERS_CONFIG[listType]}
            rows={lists.map((list) => {
                return listType === ListType.LIST ? { ...list, flags: list.flags.join(LIST_FLAGS_DELIMITER) } : list;
            })}
            itemsPerPageOptions={ITEMS_PER_PAGE_OPTIONS}
            showPaginationOptions={listType === ListType.LIST}
            customRowTableCell={CustomRowTableCell}
            emptyTableText={'User has no lists.'}
            selectableOptions={selectableOptions}
        />
    );
};

const CustomRowTableCell: React.VFC<CustomRowTableCellProps<string>> = (props) => {
    const { row, headerId } = props;
    switch (headerId) {
        case 'listId':
            return <LinkTableCellContent data={row.listId} text={row.listId} />;
        case 'listClass':
            return row.listClass !== 'LIST' ? (
                <LinkTableCellContent data={row.listId} text={row.listClass} />
            ) : (
                <DefaultTableCellContent data={row[headerId]} />
            );
        case 'publik':
            return <DefaultTableCellContent data={row.publik ? 'Public' : 'Private'} />;
        default:
            return <DefaultTableCellContent data={row[headerId]} />;
    }
};

const LinkTableCellContent = ({ data, text }) => {
    const { navigateToList } = useGlobalContext();
    return <Link onClick={() => navigateToList(data)}>{text}</Link>;
};

const DefaultTableCellContent = ({ data }) => <>{data}</>;
